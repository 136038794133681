body {
  background-color: white;
  color: black;
  font: 1rem/1.5 sans-serif;
  text-align: left;
  overflow-y: scroll;
}

.container {
  padding: 0 3rem;
  padding-top: 1rem;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 0 2rem;
    padding-top: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .container {
    padding: 0 1.5rem;
    padding-top: 1rem;
  }
}

.containerbig {
  margin: 0 auto;
  padding: 0 3rem;
  padding-bottom: 5rem;
  padding-top: 5rem;
}

.center {
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.innercol {
  position: relative;
  min-height: 15px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 15px;
}

.Login > input {
    border-radius: 4px;
    color: black;
    margin-top: 10px;
    width: 230px;
    height: 50px;
    border: none;
    font-size: 1.2rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    outline: none;
    padding-left: 0.55rem;
}

#columns_2 {
  columns: 2;
  column-width: 50px;
  -webkit-column-width: 50px;
  -moz-column-width: 50px;
}

#columns_2d {
  columns: 2 0px;
}

footer {
  margin-top: 20px;
  text-align: center;
}

.below {
  height: 100px;
}

#bold {
  font-weight: bold;
}

#wrapper {
  position: relative;
  min-height: 100%;
  height: auto !important;
  height: 100%;
}
#content {
  padding-bottom: 0px;
}
#footer {
  background: url(Hintergrund.png);
  position: relative;
  width: 100%;
  background-size: cover;
  margin-top: 10%;
}

#green {
  color: green;
}

#blue {
  color: blue;
}

.black {
  background-color: rgb(5, 31, 67);
}

.blue {
  background-color: #007aa3;
}
.green {
  background-color: #4caf50;
}

.weisserhintergrund {
  background-size: 0% 100%;
  font-style: italic;
}

.weisserhintergrund {
  background-image: linear-gradient(white,white);
  color: black;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size,color;
  transition-duration: 400ms;
}

.roterhintergrund {
  background-size: 0% 100%;
  font-style: italic;
  background-image: linear-gradient(red,red);
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.blauerhintergrund {
  background-size: 0% 100%;
  font-style: italic;
  background-image: linear-gradient(#007aa3,#007aa3);
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

a {
  color: blue;
}

a:hover {
  color: black;
  transition: color 650ms;
}

p {
  margin-bottom: 8px;
}

.gross {
  font-size: 20px;
}

.geschlecht {
  border-radius: 7px;
  width: 150px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

.mitglied {
  border-radius: 7px;
  width: 230px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.anzahl {
  border-radius: 7px;
  width: 60px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.TShirt {
  border-radius: 7px;
  width: 75px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.name {
  border-radius: 7px;
  width: 200px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.alter {
  font: 1rem/1.5 sans-serif;
  border-radius: 7px;
  width: 150px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.groesse {
  border-radius: 7px;
  width: 110px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

.schwimmen {
  border-radius: 7px;
  width: 210px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

.fahrrad {
  border-radius: 7px;
  width: 120px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

.bemerkungen {
  border-radius: 7px;
  width: 250px;
  height: 100px;
  font-size: 18px;
  text-align: left;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
  padding-left: 5px;
}

.link {
  border-radius: 7px;
  width: 250px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

@media screen and (max-width: 740px) {
  .ligatabelle {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.ligatabelle td {
  padding: 5px;
  text-align: center;
  font-size: 0.75rem;
}
.ligatabelle th {
  background-color: rgb(255, 63, 63);
  color: white;
  text-align: center;
  padding: 5px;
  height: 3rem;
  width: flex;
}
.textUnterTabelle {
  text-align: left;
  font-size: 0.6rem;
  width: 530px;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.erziehung {
  border-radius: 7px;
  width: 200px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.email {
  border-radius: 7px;
  width: 200px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.telefon {
  border-radius: 7px;
  width: 200px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.kleineSchrift {
  font-size: 5px,
}

.linkehaelfte {
  text-align: left;
  width: 50%;
  float: left;
  border-right: 2px solid;
  padding-top: 10px;
  padding-right: 10px;
}

.rechtehaelfte {
  text-align: left;
  float: left;
  width: 50%;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.showmore select {
  color: black;
  border: 3px solid black;
  border-radius: 0.3rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 50px;
  height: 25px;
  margin-left: 10px;
  margin-top: 5px;
}

.showmore button {
  position: relative;
  background-color: transparent;
  color: #007aa3;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 2px;
  margin-top: 5px;
}

.showmore button:hover {
  background-color: #007aa3;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 650ms;
  cursor: pointer;
}

.muelleimer button {
  position: absolute;
  border: none;
  color: red;
  font-size: 30px;
  background: none;
  box-shadow: none;
  margin-top: -9px;
  margin-left: -25px;
}

.muelleimer button:hover {
  cursor: pointer;
  background: none;
  box-shadow: none;
  color: red;
}

.showmoreTopf button {
  position: relative;
  background-color: transparent;
  color: red;
  border: 3px solid red;
  border-radius: 0.3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 4px;
  margin-top: 40px;
}

.showmoreTopf button:hover {
  background-color: red;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 650ms;
  cursor: pointer;
}

.notizen input {
  width: 70%;
  max-width: 2000px;
  position: relative;
  background-color: transparent;
  color: black;
  border: 1px solid red;
  border-radius: 0.3rem;
  outline: none;
  padding: 4px;
  margin-left: 5px;
}

.tabelleprodukte1 td {
  padding: 4px;
  text-align: center;
  font-size: 15px;
}
.tabelleprodukte1 th {
  font-size: 12px;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 4px;
  height: 3rem;
  width: 240px;
}
.tabelleprodukte1 table {
  margin-bottom: 2rem;
  overflow-x: scroll;
}
.tabelleprodukte1 td:nth-child(1) {
  font-size: 15px;
  font-weight: bold;
}
.tabelleprodukte1 tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Jetzt Version 2 */

.tabelleprodukte2 td {
  padding: 4px;
  text-align: center;
  font-size: 17px;
}
.tabelleprodukte2 th {
  font-size: 15px;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 4px;
  height: 3rem;
  width: 240px;
}
.tabelleprodukte2 table {
  margin-bottom: 2rem;
  overflow-x: scroll;
}
.tabelleprodukte2 td:nth-child(1) {
  font-size: 17px;
  font-weight: bold;
}
.tabelleprodukte2 tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Jetzt Version 3 */

.tabelleprodukte3 td {
  padding: 4px;
  text-align: center;
  font-size: 20px;
}
.tabelleprodukte3 th {
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 4px;
  height: 3rem;
  width: 240px;
}
.tabelleprodukte3 table {
  margin-bottom: 2rem;
  overflow-x: scroll;
}
.tabelleprodukte3 td:nth-child(1) {
  font-size: 20px;
  font-weight: bold;
}
.tabelleprodukte3 tr:nth-child(even) {
  background-color: #f2f2f2;
}

#textboxklein > input {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
  height: 30px;
  width: 60px;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  outline: none;
}

/* Jetzt Hochkant */

.tabelleprodukteHochkant1 td {
  padding: 4px;
  text-align: center;
  font-size: 10px;
}
.tabelleprodukteHochkant1 th {
  font-size: 8px;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 4px;
  height: 3rem;
  width: 240px;
}
.tabelleprodukteHochkant1 table {
  margin-bottom: 2rem;
  overflow-x: scroll;
}
.tabelleprodukteHochkant1 td:nth-child(1) {
  font-size: 10px;
  font-weight: bold;
}
.tabelleprodukteHochkant1 tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Jetzt Hochkant Version 2 */

.tabelleprodukteHochkant2 td {
  padding: 4px;
  text-align: center;
  font-size: 11px;
}
.tabelleprodukteHochkant2 th {
  font-size: 9px;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 4px;
  height: 3rem;
  width: 240px;
}
.tabelleprodukteHochkant2 table {
  margin-bottom: 2rem;
  overflow-x: scroll;
}
.tabelleprodukteHochkant2 td:nth-child(1) {
  font-size: 11px;
  font-weight: bold;
}
.tabelleprodukteHochkant2 tr:nth-child(even) {
  background-color: #f2f2f2;
}

.exceloutput button {
  position: relative;
  color: blue;
  border-radius: 4px;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  padding: 4px;
}

.exceloutput :hover {
  color: black;
  transition: color 650ms;
  cursor: pointer;
}

.SchrittErklaerung {
  width: 1000px;
  height: 1100px;
  position: relative;
  background: url(SchrittErklaerung.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.SchrittErklaerungHoch250px {
  width: 250px;
  height: 988px;
  position: relative;
  background: url(SchrittErklaerungHoch.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.SchrittErklaerungHoch300px {
  width: 300px;
  height: 1185px;
  position: relative;
  background: url(SchrittErklaerungHoch.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.SchrittErklaerungHoch400px {
  width: 400px;
  height: 1580px;
  position: relative;
  background: url(SchrittErklaerungHoch.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.SchrittErklaerungHoch500px {
  width: 500px;
  height: 1976px;
  position: relative;
  background: url(SchrittErklaerungHoch.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.SchrittErklaerungHoch600px {
  width: 600px;
  height: 2371px;
  position: relative;
  background: url(SchrittErklaerungHoch.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Anmeldeformularklein {
  width: 241px;
  height: 300px;
  position: relative;
  background: url(Anmeldeformular.png);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Anmeldeformulargross {
  width: 382px;
  height: 477px;
  position: relative;
  background: url(Anmeldeformular.png);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.FPL {
  width: 114.71px;
  height: 96.33px;
  position: flex;
  background: url(FPL.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 110px;
  margin-top: -53px;
}

.ErstellungAnmeldeformulargross {
  width: 487px;
  height: 479px;
  position: relative;
  background: url(ErstellungAnmeldeformular.png);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
  margin-top: 10px;
}

.xscroll {
  margin-left: -20px;
  padding-left: 20px;
  height: 515px;
  overflow-x: auto;
  overflow-y: hidden;
}

.Zeitaufwand400 {
  width: 400px;
  height: 259px;
  position: relative;
  background: url(Zeitaufwand.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Zeitaufwand180 {
  width: 277px;
  height: 180px;
  position: relative;
  background: url(Zeitaufwand.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Ausgaben400 {
  width: 400px;
  height: 214px;
  position: relative;
  background: url(Ausgaben.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Ausgaben150 {
  width: 280px;
  height: 150px;
  position: relative;
  background: url(Ausgaben.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.IchFoto300 {
  width: 300px;
  height: 183px;
  position: relative;
  background: url(Ich-Bild.png);
  background-size: cover;
}

.IchFoto450 {
  width: 450px;
  height: 275px;
  position: relative;
  background: url(Ich-Bild.png);
  background-size: cover;
}

.MehrfachtrainingPfeilHochHochkant100px {
  width: 100px;
  height: 82px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilHoch2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilHochHochkant200px {
  width: 200px;
  height: 161px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilHoch2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilHochHochkant300px {
  width: 300px;
  height: 241px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilHoch2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilHochHochkant400px {
  width: 400px;
  height: 321px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilHoch2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilHochHochkant500px {
  width: 500px;
  height: 401px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilHoch2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilHochHochkant600px {
  width: 600px;
  height: 483px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilHoch2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Mehrfachtraining100px {
  width: 100px;
  height: 29px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}.Mehrfachtraining200px {
  width: 200px;
  height: 58px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}.Mehrfachtraining300px {
  width: 300px;
  height: 87px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}.Mehrfachtraining400px {
  width: 400px;
  height: 116px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}.Mehrfachtraining500px {
  width: 500px;
  height: 145px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.Mehrfachtraining600px {
  width: 600px;
  height: 174px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.Mehrfachtraining700px {
  width: 700px;
  height: 203px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.Mehrfachtraining800px {
  width: 800px;
  height: 232px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.Mehrfachtraining900px {
  width: 900px;
  height: 261px;
  position: relative;
  top: 0;
  left: 0;
  background: url(Mehrfachtraining.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}


.MehrfachtrainingPfeilRunter100px {
  width: 100px;
  height: 32px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter200px {
  width: 200px;
  height: 63px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter300px {
  width: 300px;
  height: 95px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter400px {
  width: 400px;
  height: 126px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter500px {
  width: 500px;
  height: 158px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter600px {
  width: 600px;
  height: 189px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter700px {
  width: 700px;
  height: 221px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter800px {
  width: 800px;
  height: 252px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter900px {
  width: 900px;
  height: 284px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunter1000px {
  width: 1000px;
  height: 315px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

/* Version 2 */

.MehrfachtrainingPfeilRunterHochkant200px {
  width: 200px;
  height: 166px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunterHochkant300px {
  width: 300px;
  height: 249px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunterHochkant400px {
  width: 400px;
  height: 332px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunterHochkant500px {
  width: 500px;
  height: 415px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}
.MehrfachtrainingPfeilRunterHochkant600px {
  width: 600px;
  height: 498px;
  position: relative;
  top: 0;
  left: 0;
  background: url(MehrfachtrainingPfeilRunter2Zeilen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.td {
  width: 50%;
}

.gruenderstory td {
  padding: 9px;
}

.Test {
  border-left: 2px solid transparent;
  border-left-color: black;
}
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 1.3rem;
  margin-left: -0.7825rem;
}

#roteFarbeZahl {
  color: red;
  font-size: 20px;
  margin-top: -8px;
}

#grueneFarbeZahl {
  color: green;
  font-size: 16px;
  margin-left: 10px;
}

.TabelleAngaben {
  position: relative;
  margin-top: 10px;
}

.combobox select {
  position: relative;
  margin-left: 10px;
  color: black;
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  margin-right: 20px;
}

.combobox button {
  position: relative;
  border-radius: 4px;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  padding: 4px;
}

.combobox select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.cursorselect select:hover {
  cursor: pointer;
  color: black;
  transition: color 650ms;
}

.topfdetail td {
  display: relative;
  padding: 2px;
  text-align: center;
}

.topfdetail select {
  font-size: 14px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
}

.topfdetail select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.normal button {
  position: relative;
  background-color: transparent;
  color: #007aa3;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 4px;
}
.normal :hover {
  background-color: #007aa3;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 1000ms;
  transition: box-shadow 650ms;
  cursor: pointer;
}

.info button {
  color: #007aa3;
  position: relative;
  background-color: transparent;
  border-radius: 0.3rem;
  border: none;
  font-size: 20px;
  padding-left: 5px;
}
.info :hover {
  cursor: pointer;
}

.quadratisch130px button {
  position: relative;
  background-color: transparent;
  color: #007aa3;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  width: 130px;
  height: 130px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
  padding: 4px;
  margin-top: 15px;
}

.quadratisch130px :hover {
  background-color: #007aa3;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 1000ms;
  cursor: pointer;
}

.quadrat button {
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  background-color: transparent;
  color: #007aa3;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  width: 30%;
  height: 150px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.5);
  padding: 4px;
}

.quadrat :hover {
  background-color: #007aa3;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 1000ms;
  cursor: pointer;
}

.namensauswahl {
  width: 100%;
  position: relative;
}

.namensauswahl input {
  position: relative;
  color: black;
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 2px;
  margin-right: 0;
  margin-left: 1rem;
  margin-top: 5px;
}

.datenanzeige {
  position: relative;
}

.datenanzeige input {
  width: 140px;
  position: relative;
  color: black;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 2px;
  margin-left: 1rem;
  margin-top: 10px;
}

.ZeitenTabelle {
  text-align: center;
  width: 750px;
  float: left;
}

.platzauswahltabellegross {
  height: 380px;
}

@media screen and (max-width: 815px) {
  .platzauswahltabellegross {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.sterne button {
  background-color: white;
  border: none;
  font-size: 30px;
  width: 30px;
  outline: none;
}

.sterne button:hover {
  cursor: pointer;
  color: gold;
}

.gold button {
  background-color: white;
  border: none;
  font-size: 30px;
  width: 30px;
  outline: none;
  color: gold;
}

.gold button:hover {
  cursor: pointer;
}

.version select {
  border-radius: 4px;
  color: black;
  margin-top: 1px;
  width: 260px;
  height: 50px;
  border: none;
  font-size: 1.1rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 0.55rem;
}

.partner {
  font-size: 22px;
  height: 500px;
  background-image: linear-gradient(to top right,rgba(145, 0, 19, 0.2) 0,rgba(0, 84, 163, 0.2) 50%,rgba(0, 196, 186, 0.2));
}

.NRW {
  width: 256px;
  height: 270px;
  position: relative;
  margin-top: 70px;
  margin-left: 180px;
  background: url(NRW.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Gruenderstipendium {
  width: 235px;
  height: 80px;
  position: absolute;
  margin-top: -275px;
  margin-left: 527px;
  background: url(Gruenderstipendium.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Kreissparkasse {
  width: 248px;
  height: 73px;
  position: absolute;
  margin-top: -171px;
  margin-left: 521px;
  background: url(Kreissparkasse.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

.Existenzgruenderpreis {
  width: 259px;
  height: 80px;
  position: absolute;
  margin-top: -75px;
  margin-left: 515px;
  background: url(Existenzgruenderpreis.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
}

@media screen and (max-width: 1000px) {
  .partner {
    zoom: 0.7;
   }
}

@media screen and (max-width: 750px) {
  .partner {
    zoom: 0.45;
   }
}

@media screen and (max-width: 500px) {
  .partner {
    zoom: 0.3;
   }
}

.produkttabelle{
  width: 90%;
  position: relative;
}

.produkttabelle td{
  width: 100px;
  position: center;
}

@media screen and (max-width: 800px) {
  .produkttabelle{
    zoom: 0.8;
  }
}
@media screen and (max-width: 650px) {
  .produkttabelle{
    zoom: 0.7;
  }
}
@media screen and (max-width: 450px) {
  .produkttabelle{
    zoom: 0.6;
  }
}

.produkt {
  width: 95%;
  height: 40px;
  position: center;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 25px;
  box-shadow: 0 0px 10px rgba(179, 173, 173, 0.5);
}

.auge button {
  position: absolute;
  width: 50px;
  height: 52px;
  border: none;
  background: none;
  font-size: 30px;
  margin-left: -50px;
  cursor: pointer;
}
.strich {
  position: absolute;
  font-size: 35px;
  margin-top: 3px;
  margin-left: -30px;
  cursor: pointer;
}

.Zelle button {
  background: none;
  border: none;
}
.Zelle button:hover {
  background-color: #4caf50;
  cursor: pointer;
}

.Textfeld input {
  font-size: 14px;
  text-align: center;
  position: relative;
  height: 22px;
  width: 150px;
  border: 1px solid #007aa3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  outline: none;
  margin-top: 5px;
}




#tablist li[aria-selected="true"] {
  background-color: white;
  padding: 0.5em 0.5em 11px;  
}


#tablist li {	
  border: 1px solid black;
  border-bottom: none;
  border-radius: 0.5em 0.5em 0 0;
  display: inline;
  font-weight: bold;
  height: 1em;
  padding: 0.5em 0.5em 10px;
}

#tabcontent {
  background-color: white;
  border: 1px solid black; 
  margin-top: 10px;
  padding: 1em;
}

#tabcontentTabelle {
  background-color: white;
  margin-top: 10px;
  padding: 1em;
}

#tablist .grauerbutton button {
  background-color: lightgrey;
  border: none;
  width: 100px;
  border-radius: 0.5em 0.5em 0 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 10px;
}
#tablist .grauerbutton button:hover {
  border: none;
  background-color: yellow;
  cursor: pointer;
}

#tablist .weisserbutton button {
  background-color: white;
  border: none;
  width: 100px;
  border-radius: 0.5em 0.5em 0 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 10px;
}

#tablist .grauerbuttonTabelle button {
  background-color: lightgrey;
  border: none;
  width: flex;
  border-radius: 0.5em 0.5em 0 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 10px;
}
#tablist .grauerbuttonTabelle button:hover {
  border: none;
  background-color: yellow;
  cursor: pointer;
}

#tablist .weisserbuttonTabelle button {
  background-color: white;
  border: none;
  width: flex;
  border-radius: 0.5em 0.5em 0 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 10px;
}