.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.innercol {
  position: relative;
  min-height: 15px;
}

.innercolw {
  position: relative;
  margin-left: 200px;
}

.innercol > select {
  margin-left: -18px;
}

.innercolr > select {
  width: 300px;
  margin: 0;
  margin-bottom: 25px;
}

.innercol > input {
  border-radius: 4px;
  color: black;
  margin-top: 1px;
  width: 250px;
  height: 50px;
  border: none;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  -webkit-appearance: button;
  outline: none;
  padding-left: 0.55rem;
}

.innercol > select {
  border-radius: 4px;
  color: black;
  margin-top: 1px;
  width: 250px;
  height: 50px;
  border: none;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 0.55rem;
}

.innercolr > select {
  border-radius: 4px;
  color: black;
  margin-top: 1rem;
  width: 500px;
  height: 50px;
  border: none;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  padding-left: 0.55rem;
}

.innercolcheck {
  display: flex;
  position: relative;
}

.innercolcheck input {
  margin-top: 0.9rem;
  margin-left: 0;
}

.innercolcheck label {
  margin-top: 0.7rem;
  margin-left: 0.5rem;
  size: 10rem;
}

.normal button {
  position: relative;
  background-color: transparent;
  color: #007aa3;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 4px;
}

.normal :hover {
  background-color: #007aa3;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 1000ms;
  transition: box-shadow 650ms;
  cursor: pointer;
}

.innercolbutton1 button {
  border-radius: 4px;
  margin-left: 2.9rem;
  margin-top: 0.7rem;
  font-size: 1.2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

#columns_2 {
  columns: 2;
  column-width: 50px;
}

#roteFarbe {
  color: red;
  padding-top: -1rem;
}

@media screen and (max-width: 580px) {
  #columns_2 {
    columns: 1;
    column-width: 50px;
  }

  .innercol > select {
    margin-left: -15px;
  }

  .innercol > input {
    margin-left: -15px;
  }

  .innercolr > select {
    width: 70%;
    margin: 0;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 410px) {
  .innercolr > select {
    width: 220px;
  }
  .innercolcheck label {
    margin-top: 0.7rem;
    margin-left: 1rem;
  }
}


.extremklein {
  position: relative;
  min-height: 15px;
}

.extremklein > input {
  border-radius: 4px;
  color: black;
  margin-top: 1px;
  width: 200px;
  height: 30px;
  border: none;
  font-size: 13px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  -webkit-appearance: button;
  outline: none;
  padding-left: 0.55rem;
}

.extremklein > select {
  border-radius: 4px;
  color: black;
  margin-top: 1px;
  width: 200px;
  height: 30px;
  border: none;
  font-size: 13px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  -webkit-appearance: button;
  outline: none;
  padding-left: 0.55rem;
}