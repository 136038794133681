.underline {
  text-decoration: underline;
}

.aufzaehlung li {
  padding-bottom: 10px;
}

#FarbeDemo {
  color: green;
}

u {
  color: blue;
}

u:hover {
  color: black;
  transition: color 650ms;
  cursor: pointer;
}

.normal button {
  position: relative;
  background-color: transparent;
  color: #007aa3;
  border: 3px solid #007aa3;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 4px;
}

.normal :hover {
  background-color: #007aa3;
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transition: color 650ms;
  transition: background-color 1000ms;
  transition: box-shadow 650ms;
  cursor: pointer;
}

.trainingsauswahl select {
  position: relative;
  margin-left: 10px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
  margin-right: 20px;
}

.cursorselect input {
  font-size: 14px;
  text-align: center;
  height: 22px;
  width: 150px;
  border-radius: 0.3rem;
  position: relative;
  color: black;
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 2px;
  margin-right: 0;
  margin-left: 1rem;
}

.cursorselect select {
  font-size: 14px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
}

.cursorselect select:hover {
  cursor: pointer;
  color: black;
  transition: color 650ms;
}

.platzauswahl {
  width: 100%;
  height: 290px;
}

.platzauswahltabellegross {
  height: 380px;
}

@media screen and (max-width: 815px) {
  .platzauswahltabellegross {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 945px) {
  .platzauswahl {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.linkeTabellePlatzauswahl {
  text-align: center;
}

.linkeTabelle {
  text-align: center;
  width: 720px;
  float: left;
  border-right: 2px solid;
}

.blauerrand {
  width: 117px;
  height: 40px;
  padding-top: 15px;
  text-align: left;
  font-size: 13px;
  background-color: rgb(220, 220, 220);
  border-radius: 3px;
  cursor: default;
}

.blauezelle {
  width: 117px;
  height: 40px;
  padding-top: 13px;
  text-align: left;
  font-size: 13px;
  border-radius: 3px;
  background-color: #007aa3;
  background-repeat: no-repeat;
}

.sternweiss {
  color: white;
  cursor: default;
}

.sterngold {
  color: gold;
  cursor: default;
}

.rechteTabelle {
  text-align: center;
  float: left;
  width: 100% -640px;
  padding-left: 20px;
}

.platzauswahl select {
  font-size: 14px;
  color: black;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
}

.trainingsauswahl select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.platzauswahl select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.platzauswahl input {
  font-size: 14px;
  text-align: center;
  position: relative;
  height: 22px;
  width: 150px;
  border: 2px solid #007aa3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  outline: none;
  margin-top: 5px;
}

@media screen and (max-width: 520px) {
  .topfgroessen {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.topfgroessen td {
  display: relative;
  padding: 0.5rem;
  text-align: center;
}

.topfgroessen select {
  position: relative;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
}

.topfgroessen select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.zurueckweiter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wuenschespan span {
  padding-left: 5px;
}

.wuensche select {
  position: relative;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 130px;
  margin: 0.2rem 0.5rem;
}

.wuensche select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.verbot select {
  position: relative;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 130px;
  margin: 0.2rem 0.5rem;
}

.verbot select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

@media screen and (max-width: 820px) {
  .tabellewuensche {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.tabellewuensche table {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tabelleverbot {
  margin-left: -18px;
}

#roteFarbe {
  color: red;
  padding-top: -1rem;
}
#roteFarbeDemo {
  color: red;
}

@media screen and (max-width: 666px) {
  .stundenplan {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.stundenplan td {
  padding: 2px;
  text-align: center;
  font-size: 0.75rem;
}
.stundenplan th {
  background-color: #007aa3;
  color: white;
  text-align: center;
  padding: 5px;
  height: 3rem;
  width: 120px;
}

.zeiten {
  font-size: 1rem;
  font-weight: bold;
}

.dunkler {
  background-color: #f2f2f2;
}

.demovideo video {
  width: 100%;
  height: 100%;
}
.demovideo1 video {
  width: 80%;
  height: 80%;
}

.punkte {
  margin-left: 1rem;
}
