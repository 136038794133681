*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.homeknopf {
  padding-left: 50px;
  margin-left: 100px;
  position: left;
  background: transparent;
  background-color: green;
  height: 8rem;
  width: 100px;
}

header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background: url(GIF_41ms.gif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 center;
  align-items: left;
  height: 8rem;
  box-shadow: 6px 6px 10px hsla(300, 15%, 25%, 0.3) inset;
}

.containerheader {
  margin: 0 auto;
  padding: 0 1.5rem;
}

.menu-icons {
  color: white;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1500;
  display: none;
}

.menu-icons :hover {
  color: rgb(186, 189, 12);
}
.containerheader nav {
  display: flex;
  align-items: center;
  height: 8rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.containerheader button {
  background-color: transparent;
  border: none;
  height: 8rem;
  width: 18rem;
  outline: none;
}

.containerheader button:hover {
  cursor: pointer;
}

.nav-list {
  display: flex;
  width: 100%;
}

.nav-list li {
  line-height: 7.75rem;
  position: relative;
}

.nav-list a {
  display: block;
  color: white;
  font-weight: bold;
  padding: 0 1rem;
  font-size: 1.5rem;
  transition: color 650ms;
}

.nav-list a:hover {
  color: rgb(186, 189, 12);
}

.sub-menu {
  width: 20rem;
  display: block;
  position: absolute;
  border-top: 3px solid black;
  background-color: black;
  z-index: 1000;
  top: 16rem;
  opacity: 0;
  visibility: hidden;
  transition: all 650ms ease;
}

.nav-list li:hover > .sub-menu {
  top: 7.88rem;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 880px) {
  .nav-list {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    flex-direction: column;
    display: none;
    align-items: initial;
    background-color: rgb(130, 130, 130);
    z-index: 1000;
    overflow-y: scroll;
    margin-top: 0;
  }
  .nav-list > li {
    line-height: 6rem;
  }
  .nav-list li > .sub-menu {
    opacity: 1;
    visibility: visible;
    max-height: initial;
  }
  .menu-icons {
    display: block;
  }
  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }
  .close {
    display: none;
    font-size: 3rem;
  }
  nav.active .close {
    display: block;
  }
  nav.active .menu {
    display: none;
  }
  nav.active .nav-list {
    display: flex;
  }
}
