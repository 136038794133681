.underline {
  text-decoration: underline;
}

.aufzaehlung li {
  padding-bottom: 10px;
}

#FarbeDemo {
  color: green;
}

input[type=checkbox] {
  transform: scale(1.4);
}

.blauerhintergrund {
  background-size: 0% 100%;
  font-style: italic;
  background-image: linear-gradient(#007aa3,#007aa3);
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-size,color;
  transition-duration: 400ms;
}

.trainingsauswahl select {
  position: relative;
  margin-left: 10px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
  margin-right: 20px;
}

.cursorselect input {
  font-size: 14px;
  text-align: center;
  height: 22px;
  width: 150px;
  border-radius: 0.3rem;
  position: relative;
  color: black;
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  outline: none;
  padding: 2px;
  margin-right: 0;
  margin-left: 1rem;
}

.cursorselect select {
  font-size: 14px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
}

.cursorselect select:hover {
  cursor: pointer;
  color: black;
  transition: color 650ms;
}

.platzauswahl {
  width: 100%;
  height: 290px;
}

.teamauswahl {
  width: 100%;
  height: flex;
}

.platzauswahltabellegross {
  height: 380px;
}

@media screen and (max-width: 815px) {
  .platzauswahltabellegross {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 945px) {
  .platzauswahl {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  
  .teamauswahl {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.linkeTabellePlatzauswahl {
  text-align: center;
}

.linkeTabelle {
  text-align: center;
  width: 720px;
  float: left;
  border-right: 2px solid;
}

.blauerrand {
  width: 117px;
  height: 40px;
  padding-top: 15px;
  text-align: left;
  font-size: 13px;
  background-color: rgb(220, 220, 220);
  border-radius: 3px;
  cursor: default;
}

.blauezelle {
  width: 117px;
  height: 40px;
  padding-top: 13px;
  text-align: left;
  font-size: 13px;
  border-radius: 3px;
  background-color: #007aa3;
  background-repeat: no-repeat;
}

.sternweiss {
  color: white;
  cursor: default;
}

.sterngold {
  color: gold;
  cursor: default;
}

.rechteTabelle {
  text-align: center;
  float: left;
  width: 100% -640px;
  padding-left: 20px;
}

.platzauswahl select {
  font-size: 14px;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
  height: 20px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
}

.teamauswahl select {
  border-radius: 7px;
  width: 100px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-left: 5px;
  margin-bottom: 5px;
}

.saetze {
  border-radius: 7px;
  width: 30px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

.Bonuspunkte {
  border-radius: 7px;
  width: 50px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  border: 3px solid;
  border-color: #007aa3;
  outline: none;
  margin-bottom: 5px;
}

.trainingsauswahl select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.platzauswahl select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.topfgroessen td {
  display: relative;
  padding: 0.5rem;
  text-align: center;
}

.topfgroessen select {
  position: relative;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 60px;
}

.topfgroessen select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

/*rgba(0, 0, 126, 0.788)*/
#blaueFarbe {
  color: blue;
}

.zurueckweiter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wuenschespan span {
  padding-left: 5px;
}

.wuensche select {
  position: relative;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 130px;
  margin: 0.2rem 0.5rem;
}

.wuensche select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.verbot select {
  position: relative;
  color: black;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  outline: none;
  width: 130px;
  margin: 0.2rem 0.5rem;
}

.verbot select:focus {
  outline-color: blue;
  outline-style: auto;
  outline-width: 5px;
}

.tabellewuensche table {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tabelleverbot table {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

#roteFarbe {
  color: red;
  padding-top: -1rem;
}

#roteFarbeGross {
  color: red;
  padding-top: -1rem;
  font-size: 25px;
}

.stundenplan td {
  padding: 5px;
  text-align: center;
  font-size: 0.75rem;
}
.stundenplan th {
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 5px;
  height: 3rem;
  width: 120px;
}

.zeiten {
  font-size: 1rem;
  font-weight: bold;
}

.dunkler {
  background-color: #f2f2f2;
}

.exceloutput :hover {
  color: black;
  transition: color 650ms;
  cursor: pointer;
}
