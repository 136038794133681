.underheader {
  width: 100%;
  position: relative;
  background-color: lightgrey;
  height: 7rem;
  box-shadow: 6px 6px 10px hsla(300, 15%, 25%, 0.3) inset;
  padding-top: 1rem;
}

.datenschutzerklaerung li {
  text-indent: -1.75rem;
  margin: 0.33rem 0.5rem 0.5rem 2rem;
}
