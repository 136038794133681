.footer {
  margin-top: 2rem;
  position: relative;
  align-items: center;
}

.containerfooter ul {
  text-align: center;
}

.containerfooter nav {
  display: block;
  align-items: center;
  height: 7.5rem;
}

.copyright {
  font-size: 90%;
  color: white;
}

.navbar-nav {
  padding-top: 1rem;
}

.navbar-nav li {
  display: inline;
  margin: 0 0.5rem;
}

.weiss {
  color: white;
}

.weiss:hover {
  color: white;
}

@media screen and (max-width: 600px) {
  .containerfooter {
    margin-top: 0;
    text-align: center;
  }
  .navbar-nav {
    width: 100%;
    text-align: center;
  }

  #zentriertfooter {
    width: 100%;
    margin: 0 auto;
  }
  .navbar-nav li {
    display: block;
  }
  .querstrich {
    display: none;
  }
}

#darkbluecolor {
  color: white;
}
